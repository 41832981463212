<template>
  <div id="sysUserSetPwd">
    <Modal
      class="sys-user-set-pwd-modal"
      v-model="flag"
      width="400"
      @on-cancel="close">
      <p slot="header">系统用户 - 设置密码</p>
      <Form
        @submit.native.prevent
        :label-width="130"
        :model="formd"
        :rules="rules"
        ref="form"
      >
        <FormItem label="你的登录密码：" prop="OldPassword">
          <Input v-model="formd.OldPassword" type="password" />
        </FormItem>
        <FormItem label="新密码：" prop="Password">
          <Input v-model="formd.Password" type="password" />
        </FormItem>
        <FormItem label="确认密码：" prop="Password2">
          <Input v-model="formd.Password2" type="password" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" @click="close">取消</Button>
        <Button type="primary" :loading="posting" @click="ok">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      posting: false,
      formd: {},
      rules: {
        Password: [{ required: true, message: " " }],
        Password2: [
          { required: true, message: " " },
          {
            validator: (rule, value, callback) => {
              if (!this.formd.Password2) {
                callback(new Error(" "))
              } else if (this.formd.Password != this.formd.Password2) {
                callback(new Error("二次输入密码不一致"))
              } else {
                callback()
              }
            }
          }
        ],
        OldPassword: [{ required: true, message: " " }]
      }
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      this.formd.UserId = id
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.formd = {};
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postData();
        }
      });
    },
    // ==========
    postData() {
      this.posting = true;
      let param = this.formd;
      delete param.Password2;
      this.$axios
        .post("/Api/BeikeUserClient/ResetPassword", param)
        .then(res => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("修改成功");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch(err => {
          this.posting = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    }
  }
};
</script>

