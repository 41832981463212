<template>
  <div id="sysUserAdd">
    <Modal @on-cancel="close" class="sys-user-add-modal" v-model="flag" width="600">
      <p slot="header">系统用户 - 新增</p>
      <Form
        :label-width="120"
        :model="formd"
        :rules="rules"
        @submit.native.prevent
        inline
        ref="form"
      >
        <FormItem label="用户名：" prop="UserName" required>
          <Input v-model="formd.UserName" />
        </FormItem>
        <FormItem label="用户帐号：" prop="UserCode" required>
          <Input v-model="formd.UserCode" />
        </FormItem>
        <FormItem label="用户密码：" prop="Password" required>
          <Input type="password" v-model="formd.Password" />
        </FormItem>
        <FormItem label="确认密码：" prop="Password2" required>
          <Input type="password" v-model="formd.Password2" />
        </FormItem>
        <FormItem label="公司：">
          <Select
            @on-change="onClient"
            clearable
            filterable
            label-in-value
            v-model="formd.ClientId"
          >
            <Option :key="item.Id" :value="item.Id" v-for="item in clientLst">{{ item.Name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="手机号：">
          <Input v-model="formd.Mobile" />
        </FormItem>
        <FormItem label="固话">
          <Input v-model="formd.Tel" />
        </FormItem>
        <FormItem label="Email：">
          <Input v-model="formd.Email" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="close" type="text">取消</Button>
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      posting: false,
      formd: { UserType: 2 },
      rules: {
        UserType: [{ required: true, type: "number", message: " " }],
        UserName: [{ required: true, message: " " }],
        UserCode: [{ required: true, message: " " }],
        Password: [{ required: true, message: " " }],
        Password2: [
          {
            validator: (rule, value, callback) => {
              if (!this.formd.Password2) {
                callback(new Error(" "));
              } else if (this.formd.Password != this.formd.Password2) {
                callback(new Error("与用户密码不一致"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      clientLst: [],
    };
  },
  methods: {
    open() {
      this.flag = true;
      this.reqClient();
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.formd = { UserType: 2 };
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    // ==========
    postData() {
      this.posting = true;
      let param = this.formd;
      param.Status = 1;
      delete param.Password2;
      this.$axios
        .post("/Api/BeikeUserClient/Add", param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("新增成功");
            this.close();
            this.$emit("on-add");
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    // ==========
    onUserType(value) {
      if (value != 1) {
        this.formd.DeptId = "";
        this.formd.DeptName = "";
      }
    },
    // ==========
    reqClient() {
      this.$axios
        .get("/Api/BaseTrader/QueryPg", {
          params: { PageIndex: 1, PageSize: 99, Type: 1 },
        })
        .then((res) => {
          if (res.result) {
            this.clientLst = res.data.DataSet;
          }
        });
    },
    onClient(obj) {
      if (obj) {
        this.formd.ClientName = obj.label;
      } else {
        this.formd.ClientName = "";
      }
    },
  },
};
</script>
<style lang="less">
.sys-user-add-modal {
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>
